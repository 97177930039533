import React, { useEffect, useState } from "react";
import NftLoot from "../../abis/NftLoot.json";
import Identicon from "identicon.js";
import Navbar from "./Navbar";
import Main from "./Main";
import Web3 from "web3";
import TextToImage from "./TextToImage";
import Avatars, { createAvatar } from '@dicebear/avatars';
import sprites from '@dicebear/avatars-avataaars-sprites';

const chest = ["Ring Mail", "Big Chest", "Powerful chest", "Biggest chest"];

const foot = ["foot", "normal foot", "black shoe", "white shoe"];

const hand = ["normal hand", "big hand", "small hand", "powerfull hand"];

const head = ["good helmet", "silver helmet", "cap", "golden cap"];

const min = 1;

const max = 4;

//Depricated so commented the code

//Declare IPFS
// const ipfsClient = require("ipfs-http-client");
// const ipfs = ipfsClient({
//  host: "ipfs.infura.io",
//  port: 5001,
//  protocol: "https",
// }); // leaving out the arguments will default to these values

// Declare IPFS json
const ipfsJson = require("nano-ipfs-store").at("https://ipfs.infura.io:5001");


const LandingPageIndex = () => {

 const [account, setAccount] = useState("");
 const [nftLoot, setNftLoot] = useState(null);
 const [images, setImages] = useState([]);
 const [loading, setLoading] = useState(true);
 const [img, setImg] = useState("");
 const [nftWords, setNftWords] = useState([]);
 const [totalSupply, setTotalSupply] = useState("");
 const [buffer, setBuffer] = useState("");

 useEffect(() => {
  loadWeb3();
 }, []);

 // Generate random number 
 const generateNumber = () => {
  return Math.floor(Math.random() * (max - min + 1) + min)
 }

 const loadWeb3 = async () => {
  if (window.ethereum) {
   window.web3 = new Web3(window.ethereum);
   await window.ethereum.enable();
   loadBlockchainData();
   return true;
  } else if (window.web3) {
   window.web3 = new Web3(window.web3.currentProvider);
   loadBlockchainData();
   return true;
  } else {
   window.alert(
    "Non-Ethereum browser detected. You should consider trying MetaMask!"
   );
   return false;
  }
 }

 const loadBlockchainData = async () => {
  const web3 = window.web3;
  // Load account
  const accounts = await web3.eth.getAccounts();

  setAccount(accounts[0]);
  // Network ID
  const networkId = await web3.eth.net.getId();
  const networkData = NftLoot.networks[networkId];
  if (networkData) {
   const nftLoot = new web3.eth.Contract(NftLoot.abi, networkData.address);
   setNftLoot(nftLoot);

   const totalSupply = await nftLoot.methods.totalSupply().call();
   setTotalSupply(totalSupply);
   console.log("total Supply", totalSupply);

   // Load words.

   // Load images
   for (var i = 1; i <= totalSupply; i++) {
    const singleNft = await nftLoot.methods.nftLootList(i - 1).call();
    const nftWord = await nftLoot.methods.nftWords(i - 1).call();
    setImages((prevState) => ([...prevState, singleNft]));
    console.log("singleNft", singleNft);
    setNftWords((prevState) => ([...prevState, nftWord]));
   }

   setLoading(false);
  } else {
   window.alert("instaDapp contract not deployed to detected network.");
  }
 }

 // grand mint access

 const grantMintAccess = (minterAddress) => {
  setLoading(true);
  nftLoot.methods
   .grandRole("MINTER_ROLE", minterAddress)
   .send({ from: account })
   .once("receipt", (receipt) => {
    setLoading(false);
   });
 };

 // Make the NFT available 

 const putNftForSale = (event, tokenId) => {
  event.preventDefault();
  setLoading(true);
  nftLoot.methods
   .setApprovalForAll(nftLoot._address, true)
   .send({ from: account })
   .on("transactionHash", (hash) => {
    setLoading(false);
   });
 }


 // Buy Now

 // const buyNow = (event, tokenId) => {
 //  console.log("token ID", tokenId)
 //  event.preventDefault();
 //  setLoading(true);
 //  nftLoot.methods.approve(nftLoot._address, tokenId)
 //   .send({ from: account })
 //   .on("transactionHash", (hash) => {
 //    nftLoot.methods
 //     .claimNft(account, "100000000", tokenId
 //     )
 //     .send({ from: account })
 //     .on("transactionHash", (hash) => {
 //      setLoading(false);
 //     });
 //   });
 // };

 const buyNow = (event, tokenId) => {
  event.preventDefault();
  setLoading(true);
  nftLoot.methods.approve(nftLoot._address, tokenId)
   .send({ from: account })
   .on("transactionHash", (hash) => {
    nftLoot.methods
     .claimNft("0x3a4E19A4CB9CD0A7A872932749b6ae4F7401C832", tokenId)
     .send({ from: account })
     .on("transactionHash", (hash) => {
      setLoading(false);
     });
   });
 }

 const captureFile = (event) => {
  event.preventDefault();
  const file = event.target.files[0];
  console.log("File,", file);

  const reader = new window.FileReader();
  reader.readAsArrayBuffer(file);

  reader.onloadend = () => {
   setBuffer(Buffer(reader.result))
   console.log("buffer", buffer);
  };
 };

 //  convertDataURIToBinary = (dataURI) => {
 //   var BASE64_MARKER = ";base64,";
 //   var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
 //   var base64 = dataURI.substring(base64Index);
 //   var raw = window.atob(base64);
 //   var rawLength = raw.length;
 //   var array = new Uint8Array(new ArrayBuffer(rawLength));

 //   for (let i = 0; i < rawLength; i++) {
 //    array[i] = raw.charCodeAt(i);
 //   }
 //   return array;
 //  };

 const tokenURL = async (tokenId) => {
  const url = await nftLoot.methods.tokenURI(tokenId).call();
  console.log("url", url);
  alert("Your token URI: " + url);
 };

 const convertDataURIToBinaryFF = (dataURI) => {
  var BASE64_MARKER = ";base64,";
  var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
  var raw = window.atob(dataURI.substring(base64Index));
  return Uint8Array.from(
   Array.prototype.map.call(raw, function (x) {
    return x.charCodeAt(0);
   })
  );
 };

 const uploadImage = async (description) => {
  console.log("Submitting file to ipfs...", description);
  const options = {
   base64: true
  };
  let avatars = new Avatars(sprites, options);
  let svgs = await avatars.create(description);
  console.log("SVG", svgs);
  try {
   setLoading(true)
   // let canvasTxt = document.getElementById("canvasComponent").getContext("2d");
   // canvasTxt.canvas.width = canvasTxt.measureText(description).width;
   // canvasTxt.fillText(description, "0", "20");
   // setImg(canvasTxt.canvas.toDataURL());
   // let imageData = await convertDataURIToBinaryFF(
   //  canvasTxt.canvas.toDataURL()
   // );

   let imageData = await convertDataURIToBinaryFF(
    svgs
   );

   imageData = Buffer(imageData);
   // let imageData = Buffer(svgs);
   console.log("Image data", imageData);
   // console.log("image", canvasTxt.canvas.toDataURL());

   //adding file to the IPFS

  //  ipfs.add(imageData, async (error, result) => {
  //   console.log("Ipfs result", result);
  //   if (error) {
  //    console.error(error);
  //    return;
  //   }

  //   let tempChest = chest[generateNumber()];
  //   let tempHead = head[generateNumber()];
  //   let tempHand = hand[generateNumber()];
  //   let tempFoot = foot[generateNumber()];
  //   let allWords = tempChest + tempFoot + tempHand + tempHead;

  //   const json = generateJson({ name: "", imageHash: result[0].hash, chest: tempChest, head: tempHead, hand: tempHand, foot: tempFoot, allWords: allWords }).then(async (val) => {

  //    console.log("JSONFILE IN THIS FUNCTION", val);

  //    const cid = await ipfsJson.add(val);

  //    console.log("ipfsJson CID", cid);

  //    const tokenURIHash = await ipfsJson.cat(cid);

  //    console.log("tokenURL", await ipfsJson.cat(cid));

  //    nftLoot.methods
  //     .safeMint(account, "https://ipfs.infura.io/ipfs/" + cid, description, "100000000000000000")
  //     .send({ from: account })
  //     .once("receipt", (receipt) => {
  //      setLoading(false)
  //      setImages((prevState) => ([...prevState, result[0].hash]))
  //      setNftWords((prevState) => ([...prevState, description]));
  //     });
  //   });

  //  });

  } catch (error) {
   console.log(error);
  }
 };


 // generate random words. 


 // Generate metadata json file. 

 const generateJson = async (data) => {
  const metadata = JSON.stringify({
   description: "Gaming Avatar - Dynamic avatar it is used for gaming.",
   external_url: "https://codegama.com",
   image: "https://ipfs.infura.io/ipfs/" + data.imageHash,
   name: "Gaming Avatar " + data.name,
   text: data.allWords,
   attributes: [
    {
     "trait_type": "Chest",
     "value": data.chest
    },
    {
     "trait_type": "Foot",
     "value": data.foot
    },
    {
     "trait_type": "Hand",
     "value": data.hand
    },
    {
     "trait_type": "Head",
     "value": data.head
    },
   ]
  })
  console.log("Json", metadata);
  return metadata;
 }

 return (
  <div>
   <Navbar account={account} />
   {loading ? (
    <div id="loader" className="text-center mt-5">
     <p>Loading...</p>
    </div>
   ) : (
    <>
     <Main
      images={images}
      captureFile={captureFile}
      uploadImage={uploadImage}
      tokenURL={tokenURL}
      nftWords={nftWords}
      grantMintAccess={grantMintAccess}
      buyNow={buyNow}
      putNftForSale={putNftForSale}
     />
     {/* 
     <button
      type="submit"
      className="btn btn-success btn-block"
      onClick={(event) => allow(event, key)}
     >
      Buy Now
     </button> */}

    </>
   )}
  </div>
 );
}

export default LandingPageIndex;

