import React from 'react'

//black chain

import keyChain1 from "./KeyChain/Keychain-1.png";
import keyChain2 from "./KeyChain/Keychain-2.png";
import keyChain3 from "./KeyChain/Keychain-3.png";
import keyChain4 from "./KeyChain/Keychain-4.png";
import keyChain5 from "./KeyChain/Keychain-5.png";
import keyChain6 from "./KeyChain/Keychain-6.png";
import keyChain7 from "./KeyChain/Keychain-7.png";
import keyChain8 from "./KeyChain/Keychain-8.png";
import keyChain9 from "./KeyChain/Keychain-9.png";
import keyChain10 from "./KeyChain/Keychain-10.png";
import keyChain11 from "./KeyChain/Keychain-11.png";
import keyChain12 from "./KeyChain/Keychain-12.png";
import keyChain13 from "./KeyChain/Keychain-13.png";
import keyChain14 from "./KeyChain/Keychain-14.png";
import keyChain15 from "./KeyChain/Keychain-15.png";
import keyChain16 from "./KeyChain/Keychain-16.png";
import keyChain17 from "./KeyChain/Keychain-17.png";
import keyChain18 from "./KeyChain/Keychain-18.png";
import keyChain19 from "./KeyChain/Keychain-19.png";
import keyChain20 from "./KeyChain/Keychain-20.png";
import keyChain21 from "./KeyChain/Keychain-21.png";
import keyChain22 from "./KeyChain/Keychain-22.png";
import keyChain23 from "./KeyChain/Keychain-23.png";
import keyChain24 from "./KeyChain/Keychain-24.png";
import keyChain25 from "./KeyChain/Keychain-25.png";
import keyChain26 from "./KeyChain/Keychain-26.png";
import keyChain27 from "./KeyChain/Keychain-27.png";
import keyChain28 from "./KeyChain/Keychain-28.png";
import keyChain29 from "./KeyChain/Keychain-29.png";
import keyChain30 from "./KeyChain/Keychain-30.png";

//Figaro Gold

import keyChain31 from "./KeyChain/Keychain-31.png";
import keyChain32 from "./KeyChain/Keychain-32.png";
import keyChain33 from "./KeyChain/Keychain-33.png";
import keyChain34 from "./KeyChain/Keychain-34.png";
import keyChain35 from "./KeyChain/Keychain-35.png";
import keyChain36 from "./KeyChain/Keychain-36.png";
import keyChain37 from "./KeyChain/Keychain-37.png";
import keyChain38 from "./KeyChain/Keychain-38.png";
import keyChain39 from "./KeyChain/Keychain-39.png";
import keyChain40 from "./KeyChain/Keychain-40.png";
import keyChain41 from "./KeyChain/Keychain-41.png";
import keyChain42 from "./KeyChain/Keychain-42.png";
import keyChain43 from "./KeyChain/Keychain-43.png";
import keyChain44 from "./KeyChain/Keychain-44.png";
import keyChain45 from "./KeyChain/Keychain-45.png";
import keyChain46 from "./KeyChain/Keychain-46.png";
import keyChain47 from "./KeyChain/Keychain-47.png";
import keyChain48 from "./KeyChain/Keychain-48.png";
import keyChain49 from "./KeyChain/Keychain-49.png";
import keyChain50 from "./KeyChain/Keychain-50.png";
import keyChain51 from "./KeyChain/Keychain-51.png";
import keyChain52 from "./KeyChain/Keychain-52.png";
import keyChain53 from "./KeyChain/Keychain-53.png";
import keyChain54 from "./KeyChain/Keychain-54.png";
import keyChain55 from "./KeyChain/Keychain-55.png";
import keyChain56 from "./KeyChain/Keychain-56.png";
import keyChain57 from "./KeyChain/Keychain-57.png";
import keyChain58 from "./KeyChain/Keychain-58.png";
import keyChain59 from "./KeyChain/Keychain-59.png";
import keyChain60 from "./KeyChain/Keychain-60.png";

const useKeyChains = () => {
  return  [
    keyChain1, keyChain2, keyChain3, keyChain4, keyChain5, keyChain6, keyChain7, keyChain8, keyChain9, keyChain10, 
    keyChain11, keyChain12, keyChain13, keyChain14, keyChain15, keyChain16, keyChain17, keyChain18, keyChain19, keyChain20,
    keyChain21, keyChain22, keyChain23, keyChain24, keyChain25, keyChain26, keyChain27, keyChain28, keyChain29, keyChain30,
    keyChain31, keyChain32, keyChain33, keyChain34, keyChain35, keyChain36, keyChain37, keyChain38, keyChain39, keyChain40, 
    keyChain41, keyChain42, keyChain43, keyChain44, keyChain45, keyChain46, keyChain47, keyChain48, keyChain49, keyChain50,
    keyChain51, keyChain52, keyChain53, keyChain54, keyChain55, keyChain56, keyChain57, keyChain58, keyChain59, keyChain60
  ];
}

export default useKeyChains
