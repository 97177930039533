import React from 'react'

import key1 from "./Keys/00001.png";
import key2 from "./Keys/00002.png";
import key3 from "./Keys/00003.png";
import key4 from "./Keys/00004.png";
import key5 from "./Keys/00005.png";
import key6 from "./Keys/00006.png";
import key7 from "./Keys/00007.png";
import key8 from "./Keys/00008.png";
import key9 from "./Keys/00009.png";
import key10 from "./Keys/00010.png";
import key11 from "./Keys/00011.png";
import key12 from "./Keys/00012.png";
import key13 from "./Keys/00013.png";
import key14 from "./Keys/00014.png";
import key15 from "./Keys/00015.png";
import key16 from "./Keys/00016.png";
import key17 from "./Keys/00017.png";
import key18 from "./Keys/00018.png";
import key19 from "./Keys/00019.png";
import key20 from "./Keys/00020.png";
import key21 from "./Keys/00021.png";
import key22 from "./Keys/00022.png";
import key23 from "./Keys/00023.png";
import key24 from "./Keys/00024.png";
import key25 from "./Keys/00025.png";
import key26 from "./Keys/00026.png";
import key27 from "./Keys/00027.png";
import key28 from "./Keys/00028.png";
import key29 from "./Keys/00029.png";
import key30 from "./Keys/00030.png";
import key31 from "./Keys/00031.png";
import key32 from "./Keys/00032.png";
import key33 from "./Keys/00033.png";
import key34 from "./Keys/00034.png";
import key35 from "./Keys/00035.png";
import key36 from "./Keys/00036.png";
import key37 from "./Keys/00037.png";
import key38 from "./Keys/00038.png";
import key39 from "./Keys/00039.png";
import key40 from "./Keys/00040.png";
import key41 from "./Keys/00041.png";
import key42 from "./Keys/00042.png";
import key43 from "./Keys/00043.png";
import key44 from "./Keys/00044.png";
import key45 from "./Keys/00045.png";
import key46 from "./Keys/00046.png";
import key47 from "./Keys/00047.png";
import key48 from "./Keys/00048.png";
import key49 from "./Keys/00049.png";
import key50 from "./Keys/00050.png";
import key51 from "./Keys/00051.png";
import key52 from "./Keys/00052.png";
import key53 from "./Keys/00053.png";
import key54 from "./Keys/00054.png";
import key55 from "./Keys/00055.png";
import key56 from "./Keys/00056.png";
import key57 from "./Keys/00057.png";
import key58 from "./Keys/00058.png";
import key59 from "./Keys/00059.png";
import key60 from "./Keys/00060.png";
import key61 from "./Keys/00061.png";
import key62 from "./Keys/00062.png";
import key63 from "./Keys/00063.png";
import key64 from "./Keys/00064.png";
import key65 from "./Keys/00065.png";
import key66 from "./Keys/00066.png";
import key67 from "./Keys/00067.png";
import key68 from "./Keys/00068.png";
import key69 from "./Keys/00069.png";
import key70 from "./Keys/00070.png";
import key71 from "./Keys/00071.png";
import key72 from "./Keys/00072.png";
import key73 from "./Keys/00073.png";
import key74 from "./Keys/00074.png";
import key75 from "./Keys/00075.png";
import key76 from "./Keys/00076.png";
import key77 from "./Keys/00077.png";
import key78 from "./Keys/00078.png";
import key79 from "./Keys/00079.png";
import key80 from "./Keys/00080.png";
import key81 from "./Keys/00081.png";
import key82 from "./Keys/00082.png";
import key83 from "./Keys/00083.png";
import key84 from "./Keys/00084.png";
import key85 from "./Keys/00085.png";
import key86 from "./Keys/00086.png";
import key87 from "./Keys/00087.png";
import key88 from "./Keys/00088.png";
import key89 from "./Keys/00089.png";
import key90 from "./Keys/00090.png";
import key91 from "./Keys/00091.png";
import key92 from "./Keys/00092.png";
import key93 from "./Keys/00093.png";
import key94 from "./Keys/00094.png";
import key95 from "./Keys/00095.png";
import key96 from "./Keys/00096.png";
import key97 from "./Keys/00097.png";

const useKeys = () => {
  return [
    key1, key2, key3,key4,key5,key6,key7,key8,key9,key10,
    key11, key12, key13,key14,key15,key16,key17,key18,key19,key20,
    key21, key22, key23,key24,key25,key26,key27,key28,key29,key30,
    key31, key32, key33,key34,key35,key36,key37,key38,key39,key40,
    key41, key42, key43,key44,key45,key46,key47,key48,key49,key50,
    key51, key52, key53,key54,key55,key56,key57,key58,key59,key60,
    key61, key62, key63,key64,key65,key66,key67,key68,key69,key70,
    key71, key72, key73,key74,key75,key76,key77,key78,key79,key80,
    key81, key82, key83,key84,key85,key86,key87,key88,key89,key90,
    key91, key92, key93,key94,key95,key96,key97
  ]
}

export default useKeys
